import CookieJar from "js-cookie";
import router from "./router";

import {
  SET_UNRESTRICTED_ACTIVITIES,
  SET_EXAM,
  SET_LESSON,
  SET_SCROLL_THROUGH_QUESTIONS_META,
  SET_NOTICE_LOADING_TEXT,
  SET_SELECTED_TOPIC,
  SET_SESSION,
  SET_FEATURE_FLAG,
  SET_CURRENT_QUESTION,
  SET_TOPIC_AUTOSTART_CONFIG,
  SET_STUDENT_AUTOSELECT,
  SET_STEP_BY_STEP_RESPONSES,
  SET_STEP_BY_STEP_MARKING_META,
  CLEAR_STEP_BY_STEP_QUESTION_DATA,
  SET_STEP_BY_STEP_COMPLETE,
  UPDATE_ACTIVITIES_META,
  SET_USE_ALTERNATIVE_SIGNALR_CONNECTION_STRING,
  UPDATE_TUTOR_SESSION_TYPE,
  SET_RUM_ENABLED,
  SET_GCSE_QUERY_SESSION,
  SET_GCSE_AVAILABLE_SUBJECTS,
  SET_GCSE_SELECT_SKILL_SUBJECT,
  SET_GCSE_AVAILABLE_AREAS,
  SET_GCSE_SELECT_SKILL_AREA,
  SET_GCSE_SELECT_CURRICULUM,
  SET_GCSE_AVAILABLE_SKILLS,
  SET_GCSE_SELECT_SKILL,
  SET_GCSE_RETURNED_SKILLS,
  SET_TEMP_FEARLESS_POINTS,
  SET_FLP,
  SET_SSO_ROUTE_TO,
  SET_STUDENT_CAN_SELF_RATE,
} from "@/constants/actions";
import {
  COMMIT_UNRESTRICTED_ACTIVITIES,
  COMMIT_EXAM,
  COMMIT_LESSON,
  COMMIT_SCROLL_THROUGH_QUESTIONS_META,
  COMMIT_NOTICE_LOADING_TEXT,
  COMMIT_SELECTED_TOPIC,
  COMMIT_FEATURE_FLAG,
  COMMIT_CURRENT_QUESTION,
  COMMIT_TOPIC_AUTOSTART_CONFIG,
  COMMIT_STUDENT_AUTOSELECT,
  COMMIT_STEP_BY_STEP_RESPONSES,
  COMMIT_STEP_BY_STEP_MARKING_META,
  COMMIT_CLEAR_STEP_BY_STEP_QUESTION_DATA,
  COMMIT_STEP_BY_STEP_COMPLETE,
  COMMIT_ACTIVITIES_META,
  COMMIT_USE_ALTERNATIVE_SIGNALR_CONNECTION_STRING,
  COMMIT_TUTOR_SESSION_TYPE,
  COMMIT_RUM_ENABLED,
  COMMIT_GCSE_QUERY_SESSION,
  COMMIT_GCSE_AVAILABLE_SUBJECTS,
  COMMIT_GCSE_SELECT_SKILL_SUBJECT,
  COMMIT_GCSE_AVAILABLE_AREAS,
  COMMIT_GCSE_SELECT_SKILL_AREA,
  COMMIT_GCSE_SELECT_CURRICULUM,
  COMMIT_GCSE_AVAILABLE_SKILLS,
  COMMIT_GCSE_SELECT_SKILL,
  COMMIT_GCSE_RETURNED_SKILLS,
  COMMIT_TEMP_FEARLESS_POINTS,
  COMMIT_FLP,
  COMMIT_SSO_ROUTE_TO,
  COMMIT_STUDENT_CAN_SELF_RATE,

} from "@/constants/mutations";
import { sendWithNoTracking } from "@/utils/signalAck";

export default {
  logSessionEvent: function (
    { state, commit, dispatch },
    {
      logEvent,
      category,
      verbosity,
      openTokSessionId,
      openTokVideoSessionId,
      isAudioOnly,
    }
  ) {
    const currentTime = new Date();

    const sessionEventData = {
      sessionId: state.session ? state.session.id : null,
      tutorSessionId:
        state.session && state.session.tutor
          ? state.session.tutor.tutorSessionId
          : null,
      studentReference: state.student ? state.student.id : null,
      familyReference: state.homeFamily
        ? state.homeFamily.familyReference
        : null,
      networkSpeed: state.networkSpeed ? state.networkSpeed.toString() : null,
      userAgent: state.userAgent ? state.userAgent : null,
      category: category,
      logEvent: logEvent,
      verbosity: verbosity,
      device: state.isTablet ? "Tablet" : "Desktop",
      openTokSessionId: openTokSessionId ? openTokSessionId : null,
      openTokVideoSessionId: openTokVideoSessionId
        ? openTokVideoSessionId
        : null,
      isAudioOnly: isAudioOnly ? isAudioOnly : null,
      timeStamp: currentTime,
      currentRoute: router?.currentRoute?.value?.name,
    };

    if (verbosity === "Information" && category === "Network") {
      // Clear old session events from previous sessions
      var firstSessionEvent = null;
      if (state.sessionEvents && state.sessionEvents.length > 0) {
        firstSessionEvent = state.sessionEvents[0];
      }

      if (
        !firstSessionEvent ||
        firstSessionEvent.sessionId != sessionEventData.sessionId
      ) {
        commit("updateSessionEvents", []);
        commit("updateLastSentSessionEventTimeStamp", null);
        commit("updateNetworkSpeedDetails", null);
      }

      // Filter on the network session events to minimise unnecessary logging.
      if (state.networkSpeed) {
        let networkSpeedDetails = {
          minimum: state.networkSpeedDetails.minimum,
          maximum: state.networkSpeedDetails.maximum,
          average: state.networkSpeedDetails.average,
        };

        // Update minimum speed
        if (
          networkSpeedDetails.minimum == null ||
          networkSpeedDetails.minimum > state.networkSpeed
        ) {
          networkSpeedDetails.minimum = state.networkSpeed;
        }

        // Update maximum speed
        if (
          networkSpeedDetails.maximum == null ||
          networkSpeedDetails.maximum < state.networkSpeed
        ) {
          networkSpeedDetails.maximum = state.networkSpeed;
        }

        // Add a new event to the array
        commit("addSessionEvent", sessionEventData);

        // Make a copy that we can manipulate
        var sessionEventsArrayCopy = [...state.sessionEvents];

        // Update average speed
        let count = 0;
        let total = 0;
        sessionEventsArrayCopy.map((event) => {
          // event is the current item
          let networkSpeed = parseFloat(event.networkSpeed);
          if (!isNaN(networkSpeed)) {
            total += networkSpeed;
            count++;
          }
        });

        if (count > 0) {
          // Get the average network speed
          networkSpeedDetails.average = total / count;
          sessionEventData.networkSpeedDetails = networkSpeedDetails;

          commit("updateNetworkSpeedDetails", networkSpeedDetails);

          // Work out the difference in time between now and the last time network event logs were sent
          var timeDifference = 0;
          var updatePeriod = 5 * 60 * 1000; // 5 minutes
          if (state.lastSentSessionEventTimeStamp) {
            timeDifference =
              currentTime.getTime() -
              new Date(state.lastSentSessionEventTimeStamp).getTime();
          }

          if (state.lastSentSessionEventTimeStamp == null) {
            // Send
            commit(
              "updateLastSentSessionEventTimeStamp",
              sessionEventData.timeStamp
            );
            dispatch("postSessionEvent", sessionEventData);
          } else if (timeDifference > updatePeriod) {
            // Send and clear
            commit("updateSessionEvents", []);
            commit(
              "updateLastSentSessionEventTimeStamp",
              sessionEventData.timeStamp
            );
            dispatch("postSessionEvent", sessionEventData);
          }
        }
      }
    } else {
      dispatch("postSessionEvent", sessionEventData);
    }
  },
  postSessionEvent: async ({ state }, sessionEventData) => {
    try {
      const url = state.compass.apiBase + "/sessionLog";
      await window.axios.post(url, sessionEventData);
      return;
    } catch {
      return;
    }
  },
  sendLastLoggedSessionEvent: ({ state, commit, dispatch }) => {
    if (state.sessionEvents && state.sessionEvents.length > 0) {
      // Send the last logged item
      var lastLoggedSessionEventData =
        state.sessionEvents[state.sessionEvents.length - 1];
      if (
        lastLoggedSessionEventData &&
        lastLoggedSessionEventData.timeStamp !==
          new Date(state.lastSentSessionEventTimeStamp)
      ) {
        dispatch("postSessionEvent", lastLoggedSessionEventData);
      }

      // Clear state values for logged session event data
      commit("updateSessionEvents", []);
      commit("updateLastSentSessionEventTimeStamp", null);
    }
  },
  setTabInstanceId: ({ commit }, tabInstanceId) => {
    commit("setTabInstanceId", tabInstanceId);
    commit("setTabInvalid", false);
  },
  checkTabInstanceId: ({ state, commit }) => {
    // const storedId = localStorage.getItem("tabInstanceId");
    const storedId = CookieJar.get("tabInstanceId");
    if (state.tabInstanceId && state.tabInstanceId !== storedId) {
      commit("setTabInvalid", true);
      return true;
    }

    return false;
  },
  setAccessibilityFont: ({ commit }, fontSize) => {
    commit("setAccessibilityFont", fontSize);
  },
  setAccessibilityColor: ({ commit }, colorOverlay) => {
    commit("setAccessibilityColor", colorOverlay);
  },
  setAccessibilityModalActive: ({ commit }, active) => {
    commit("setAccessibilityModalActive", active);
  },
  setPreventSaveToLocalStorage: ({ commit }, prevent) => {
    commit("setPreventSaveToLocalStorage", prevent);
  },
  setReturnRoute: ({ commit }, route) => {
    commit("setReturnRoute", route);
  },
  setAvatarSelectorReturnRoute: ({ commit }, route) => {
    commit("setAvatarSelectorReturnRoute", route);
  },
  [SET_SELECTED_TOPIC]: ({ commit }, topic) => {
    commit(COMMIT_SELECTED_TOPIC, topic);
  },
  [SET_SESSION]: ({ commit }, session) => {
    commit("setSession", session);
  },
  [SET_NOTICE_LOADING_TEXT]: ({ commit }, text) => {
    commit(COMMIT_NOTICE_LOADING_TEXT, text);
  },
  [SET_LESSON]: ({ commit }, lesson) => {
    commit(COMMIT_LESSON, lesson);
  },
  [SET_SCROLL_THROUGH_QUESTIONS_META]: ({ commit }, questions) => {
    commit(COMMIT_SCROLL_THROUGH_QUESTIONS_META, questions);
  },
  [SET_UNRESTRICTED_ACTIVITIES]: ({ commit }, activities) => {
    commit(COMMIT_UNRESTRICTED_ACTIVITIES, activities);
  },
  [SET_EXAM]: ({ commit }, exam) => {
    commit(COMMIT_EXAM, exam);
  },
  [SET_FEATURE_FLAG]: ({ commit }, flagName, bool) => {
    commit(COMMIT_FEATURE_FLAG, flagName, bool);
  },
  [SET_CURRENT_QUESTION]: ({ commit }, question) => {
    commit(COMMIT_CURRENT_QUESTION, question);
  },
  setLatestError: ({ commit }, err) => {
    commit("setLatestError", err);
  },
  [SET_TOPIC_AUTOSTART_CONFIG]: ({ commit }, config) => {
    commit(COMMIT_TOPIC_AUTOSTART_CONFIG, config);
  },
  [SET_STUDENT_AUTOSELECT]: ({ commit }, studentReference) => {
    commit(COMMIT_STUDENT_AUTOSELECT, studentReference);
  },
  [SET_STEP_BY_STEP_RESPONSES]: ({ commit }, response) => {
    commit(COMMIT_STEP_BY_STEP_RESPONSES, response);
  },
  [SET_STEP_BY_STEP_MARKING_META]: ({ commit }, { score, isCorrect }) => {
    commit(COMMIT_STEP_BY_STEP_MARKING_META, { score, isCorrect });
  },
  [CLEAR_STEP_BY_STEP_QUESTION_DATA]: ({ commit }) => {
    commit(COMMIT_CLEAR_STEP_BY_STEP_QUESTION_DATA);
  },
  [SET_STEP_BY_STEP_COMPLETE]: ({ commit }) => {
    commit(COMMIT_STEP_BY_STEP_COMPLETE);
  },
  [UPDATE_ACTIVITIES_META]: ({ commit, state }, { event, id, payload }) => {
    const eventTypes = {
      Add: "Add",
      Completed: "Completed",
      Update: "Update",
      Clear: "Clear",
    };

    // model
    // {
    //   id: number,
    //   activityStatus: enum (NotStarted, InProgress, Completed),
    //   activityMethod: enum (Curriculum, Exam, Lesson, TopicPractice)
    //   questionsAnswered: number,
    //   questionsAnsweredCorrectly: number,
    //   skillsMastered: number,
    //   subjectReference: string,
    //   topicReference: string,
    //   topicName: string,
    //   displayName: string,
    //   defaultName: string,
    //   startTime: string,
    // }

    let updatedActivitiesMeta = null;

    if (event === eventTypes.Add) {
      // find all existing activities where activityStatus is 'InProgress' and set to 'Completed'
      const activitiesMeta = state.activitiesMeta;
      activitiesMeta.map((activity) => {
        if (activity.activityStatus === "InProgress") {
          activity.activityStatus = "Completed";
        }
        return activity;
      });

      // add payload to current activities meta
      const activity = { id, ...payload };
      activitiesMeta.unshift(activity);
      updatedActivitiesMeta = activitiesMeta;
    } else if (event === eventTypes.Update) {
      // find current activity and overwrite with payload
      const activitiesMeta = state.activitiesMeta;
      const activityIndex = activitiesMeta.findIndex(
        (activity) => activity.id === id
      );
      if (activityIndex > -1) {
        activitiesMeta[activityIndex] = {
          id,
          ...activitiesMeta[activityIndex],
          ...payload,
        };
        updatedActivitiesMeta = activitiesMeta;
      }
    } else if (event === eventTypes.Completed) {
      // find all activities where activityStatus is 'InProgress' and set to 'Completed'
      const activitiesMeta = state.activitiesMeta;
      const completedActivities = activitiesMeta.map((activity) => {
        if (activity.activityStatus === "InProgress") {
          activity.activityStatus = "Completed";
        }
        return activity;
      });
      updatedActivitiesMeta = completedActivities;
    } else if (event === eventTypes.Clear) {
      updatedActivitiesMeta = [];
    }

    if (updatedActivitiesMeta) {
      commit(COMMIT_ACTIVITIES_META, updatedActivitiesMeta);

      if (state.session?.tutor?.tutorSessionId) {
        const header = {
          messageType: "update-activities-meta",
          senderId: state.session.id,
          recipientId: state.session.tutor.tutorSessionId,
        };

        sendWithNoTracking(header, updatedActivitiesMeta);
      }
    }
  },
  [SET_USE_ALTERNATIVE_SIGNALR_CONNECTION_STRING]: (
    { commit },
    useAlternativeSignalRConnectionString
  ) => {
    commit(
      COMMIT_USE_ALTERNATIVE_SIGNALR_CONNECTION_STRING,
      useAlternativeSignalRConnectionString
    );
  },
  [UPDATE_TUTOR_SESSION_TYPE]: ({ commit }, tutorSessionType) => {
    commit(COMMIT_TUTOR_SESSION_TYPE, tutorSessionType);
  },
  [SET_RUM_ENABLED]: ({ commit }, rumEnabled) => {
    commit(COMMIT_RUM_ENABLED, rumEnabled);
  },
  [SET_GCSE_QUERY_SESSION]: ({ commit }, gcseQuerySession) => {
    commit(COMMIT_GCSE_QUERY_SESSION, gcseQuerySession);
  },
  [SET_GCSE_AVAILABLE_SUBJECTS]: ({ commit }, gcseAvailableSubjects) => {
    commit(COMMIT_GCSE_AVAILABLE_SUBJECTS, gcseAvailableSubjects);
  },
  [SET_GCSE_SELECT_SKILL_SUBJECT]: ({ commit }, gcseSelectSkillSubject) => {
    commit(COMMIT_GCSE_SELECT_SKILL_SUBJECT, gcseSelectSkillSubject);
  },
  [SET_GCSE_AVAILABLE_AREAS]: ({ commit }, gcseAvailableAreas) => {
    commit(COMMIT_GCSE_AVAILABLE_AREAS, gcseAvailableAreas);
  },
  [SET_GCSE_SELECT_SKILL_AREA]: ({ commit }, gcseSelectSkillArea) => {
    commit(COMMIT_GCSE_SELECT_SKILL_AREA, gcseSelectSkillArea);
  },
  [SET_GCSE_SELECT_CURRICULUM]: ({ commit }, gcseSelectCurriculum) => {
    commit(COMMIT_GCSE_SELECT_CURRICULUM, gcseSelectCurriculum);
  },
  [SET_GCSE_AVAILABLE_SKILLS]: ({ commit }, gcseAvailableSkills) => {
    commit(COMMIT_GCSE_AVAILABLE_SKILLS, gcseAvailableSkills);
  },
  [SET_GCSE_SELECT_SKILL]: ({ commit }, gcseSelectSkill) => {
    commit(COMMIT_GCSE_SELECT_SKILL, gcseSelectSkill);
  },
  [SET_GCSE_RETURNED_SKILLS]: ({ commit }, gcseReturnedSkills) => {
    commit(COMMIT_GCSE_RETURNED_SKILLS, gcseReturnedSkills);
  },
  [SET_TEMP_FEARLESS_POINTS]: ({ commit }, tempFearlessPoints) => {
    commit(COMMIT_TEMP_FEARLESS_POINTS, tempFearlessPoints);
  },
  [SET_FLP]: ({ commit }, fearlessPointsTotal) => {
    commit(COMMIT_FLP, fearlessPointsTotal);
  },
  [SET_STUDENT_CAN_SELF_RATE]: ({ commit }, studentCanSelfRate) => {
    commit(COMMIT_STUDENT_CAN_SELF_RATE, studentCanSelfRate);
  },
  [SET_SSO_ROUTE_TO]: ({ commit }, route) => {
    commit(COMMIT_SSO_ROUTE_TO, route);
  },
};
